<template>
  <div class="columns is-align-items-flex-end is-multiline is-mobile">
    <div class="column">
      <p class="is-size-7 has-text-weight-light">
        {{ getDate(event.date) }}
      </p>
      <span
        :class="['icon', event.passed ? 'has-text-success' : 'has-text-danger']"
      >
        <i
          class="fa fa-check-circle"
          v-if="event.passed"
          title="Passed"
          v-tippy
        />
        <i class="fa fa-times-circle" v-else title="Failed" v-tippy />
      </span>
      <span class="title is-size-5 has-text-dark">{{ title }}</span>
    </div>
    <div class="column is-narrow">
      <span
        v-if="odometerReading"
        class="title is-size-5 has-text-weight-medium"
      >
        {{ formatNumber(event.odometer.value) }} {{ getUnit(event) }}
      </span>
      <span v-else class="has-text-grey-light">
        Odometer reading not given
      </span>
    </div>
    <div
      class="column is-12 content has-text-weight-light"
      v-if="event.rfcAndComments.length > 0"
    >
      <p v-for="(advisory, index) in event.rfcAndComments" :key="index">
        {{ advisory.text }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
import { formatNumber } from 'accounting'
export default {
  name: 'TimelineMot',
  props: {
    event: {
      type: Object,
      required: true
    },
    vrm: {
      type: String,
      required: true
    }
  },
  computed: {
    odometerReading() {
      return this.event.odometer.unit && this.event.odometer.value
    },
    title() {
      return `${this.event.passed ? 'Passed' : 'Failed'} MOT Test`
    }
  },
  methods: {
    formatNumber(value) {
      return formatNumber(value)
    },
    getDate(value) {
      return formatDate(value, 'do MMMM yyyy')
    },
    getUnit(event) {
      const { unit } = event.odometer
      return unit === 'mi' ? 'miles' : 'kilometers'
    }
  }
}
</script>
